import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Self = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>Reading: <i>High Infidelity</i> by Elvia Wilk</h2>
        <p>published in the <a target="_blank" href="https://rhizomedotorg.myshopify.com/products/the-art-happens-here-net-art-anthology">Net Art Anthology</a> by Rhizome (2019).</p>
        <br></br>
        <p>I will share a copy of the essay with you. (<a target="_blank" href="https://drive.google.com/file/d/1JwnGtCL9cgsZJEcggBgJruA3jd6mvvKa/view?usp=sharing">NYU access</a>)</p>
        <p>Please take notes as you read it and write a short response (300-500). Use your writing to share your opinion, reflection and associations; did the text remind you of observations in your own virtual/actual life?</p>
        <p>A guiding question for your response can be:</p>
        <p><i>How does the virtual body relate to the biological body?</i></p>


        <br></br>
        <p>1) Complete your writing in a markdown file (.md) inside your repository.</p>
        <p>2) When done, push your work to your GitHub account and <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">submit a link at our class Wiki.</a></p>
      </MiniLayout>
    </Layout>
  )
}



export default Self
